




































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Index extends Vue {
    private logout() {
        this.$auth.logout()

        this.$router.push('login')
    }
}
