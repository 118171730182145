var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "loading",
      style: { position: _vm.fixed ? "fixed" : "absolute" }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-ring" }, [
      _c("div"),
      _c("div"),
      _c("div"),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }