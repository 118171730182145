var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("h1", [_vm._v("Chat")]),
    _vm._v(" "),
    _c("div", { staticClass: "chat" }, [
      _c("div", { staticClass: "chat__message-area" }, [
        _c(
          "div",
          {
            directives: [{ name: "chat-scroll", rawName: "v-chat-scroll" }],
            staticClass: "chat__messages"
          },
          _vm._l(_vm.messages, function(message, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  "chat__messages__item " +
                  (message.user.id === _vm.$auth.user().id
                    ? "chat__messages__item--dark"
                    : "")
              },
              [
                _c("strong", [_vm._v(_vm._s(message.user.name))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(message.message))])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.activeUser
          ? _c("div", { staticClass: "chat__typing" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.activeUser.name) +
                  " is typing...\n            "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "chat__input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newMessage,
                expression: "newMessage"
              }
            ],
            ref: "input",
            attrs: {
              type: "text",
              disabled: _vm.disableInput,
              placeholder: "Enter Message"
            },
            domProps: { value: _vm.newMessage },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSubmit($event)
              },
              keydown: _vm.handleTyping,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newMessage = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "chat__users" },
        _vm._l(_vm.users, function(user, index) {
          return _c("div", { key: index, staticClass: "chat__users__item" }, [
            _vm._v("\n                " + _vm._s(user.name) + "\n            ")
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }