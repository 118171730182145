var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "item" }, [
        _c("div", {
          staticClass: "item__image",
          style: { backgroundImage: "url('" + _vm.item.image_url + "')" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "item__content" }, [
          _c("h1", [_vm._v(_vm._s(_vm.item.title))]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("£" + _vm._s(_vm.item.price))])]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.item.description))]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: { href: _vm.item.product_url, target: "_blank" }
            },
            [_vm._v("View Product")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }