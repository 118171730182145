











































































import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
    private form = {
        title: '',
        description: '',
        price: '',
        image_url: ''
    }

    private async submit() {
        console.log(this.form)

        const response = await this.axios.post('item', {
            item: this.form
        })

        if(response.status === 200) {
            this.modalClose();
            this.$snotify.success(`Added new item: ${this.form.title}`)
        }
    }

    @Emit('modal-close')
    modalClose() {
        return true
    }
}
