









































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import L, { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LControl } from 'vue2-leaflet'
import { NONE, ALL } from 'leaflet-freedraw';
import LFreeDraw from 'vue2-leaflet-freedraw';

@Component({
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        LFreeDraw,
        LControl
    }
})
export default class Map extends Vue {
    zoom= 13
      center= latLng(55.85938716004325, -4.252847256492503);
      url= 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      attribution= ''
      currentZoom= 11.5
      currentCenter= latLng(55.85938716004325, -4.252847256492503)
      showParagraph= false
      mapOptions= {
        zoomSnap: 0.5
      }
      showMap= true
      isActive= false
      polygons= []

    update() {
      console.log(this.polygons)
    }

    zoomUpdate(zoom: any) {
      this.currentZoom = zoom;
    }
    centerUpdate(center: any) {
      this.currentCenter = center;
    }
    showLongText() {
      this.showParagraph = !this.showParagraph;
    }
    innerClick() {
      alert("Click!");
    }

   get mode() {
      return this.isActive ? ALL: NONE;
    }

    flipActive() {
      this.isActive = !this.isActive;
    }

    removeArea(polygon: any) {
      
      const polygonsNew = this.polygons.filter(item => item !== polygon)

      this.polygons = polygonsNew
    }
}
