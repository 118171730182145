var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app_wrap" },
    [
      _c("nav", { staticClass: "navbar" }, [
        _c("div", [
          _c("ul", [
            _c(
              "li",
              { staticClass: "home" },
              [
                _c("router-link", { attrs: { to: { name: "Home" } } }, [
                  _c("i", { staticClass: "fas fa-home" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Dale & Emma's Home")])
                ])
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("ul", [
            !_vm.$auth.check()
              ? _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { name: "Login" } } }, [
                      _vm._v("Login")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.$auth.check()
              ? _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { name: "Register" } } }, [
                      _vm._v("Register")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$auth.check()
              ? _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout()
                        }
                      }
                    },
                    [_vm._v("Logout")]
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { padding: "0 15px" } }, [_c("router-view")], 1),
      _vm._v(" "),
      _c("vue-snotify")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }