import Vuex from 'vuex'
import Vue from 'vue'

/**
 * We are using dynamic modules, all modules are loaded lazily
 */

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        applicationName: 'Project Freedom',
    },
    mutations: {},
    actions: {},
    getters: {},
    modules: {},
})
