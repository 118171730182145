var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("h1", [_vm._v("Register")]),
    _vm._v(" "),
    !_vm.success
      ? _c(
          "form",
          {
            attrs: { autocomplete: "off", method: "post" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.register($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.error && _vm.errors.name }
              },
              [
                _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "name", required: "" },
                  domProps: { value: _vm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.error && _vm.errors.name
                  ? _c("span", { staticClass: "help-block" }, [
                      _vm._v(_vm._s(_vm.errors.name))
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.error && _vm.errors.email }
              },
              [
                _c("label", { attrs: { for: "email" } }, [_vm._v("E-mail")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "email",
                    id: "email",
                    placeholder: "user@example.com",
                    required: ""
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.error && _vm.errors.email
                  ? _c("span", { staticClass: "help-block" }, [
                      _vm._v(_vm._s(_vm.errors.email))
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": _vm.error && _vm.errors.password }
              },
              [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v("Password")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "password", id: "password", required: "" },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.error && _vm.errors.password
                  ? _c("span", { staticClass: "help-block" }, [
                      _vm._v(_vm._s(_vm.errors.password))
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-default", attrs: { type: "submit" } },
              [_vm._v("Submit")]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }