import { Service } from 'typedi'

@Service()
class PlatformSettings {
    public readonly baseUrl: string
    public readonly csrf: string
    constructor() {
        this.baseUrl = window.location.origin
            ? window.location.origin + '/'
            : window.location.protocol + '/' + window.location.host + '/'
        this.csrf = window.laravel ? window.laravel.csrf : ''
    }
}

export default PlatformSettings
