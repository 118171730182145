import Vue from 'vue'
import Router, { Route } from 'vue-router'
import Home from '../components/views/Home.vue'
import Register from '../components/auth/Register.vue'
import Login from '../components/auth/Login.vue'
import Dashboard from '../components/views/Dashboard.vue'
import Chat from '../components/views/Chat.vue'
import Map from '../components/views/Map.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                auth: true,
            },
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                auth: false,
            },
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                auth: false,
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                auth: { roles: 'admin', redirect: '/' },
            },
        },
        {
            path: '/chat',
            name: 'Chat',
            component: Chat,
            meta: {
                auth: true,
            },
        },
        {
            path: '/map',
            name: 'Map',
            component: Map,
            meta: {
                auth: true,
            },
        },
    ],
})
