export const bootstrapEchoDebug = (echo: any) => {
    echo.connector.pusher.connection.bind('connecting', (payload: any) => {
        /**
         * All dependencies have been loaded and Channels is trying to connect.
         * The connection will also enter this state when it is trying to reconnect after a connection failure.
         */

        console.log('connecting...')
    })

    echo.connector.pusher.connection.bind('connected', (payload: any) => {
        /**
         * The connection to Channels is open and authenticated with your app.
         */

        console.log('connected!', payload)
    })

    echo.connector.pusher.connection.bind('unavailable', (payload: any) => {
        /**
         *  The connection is temporarily unavailable. In most cases this means that there is no internet connection.
         *  It could also mean that Channels is down, or some intermediary is blocking the connection. In this state,
         *  pusher-js will automatically retry the connection every 15 seconds.
         */

        console.log('unavailable', payload)
    })

    echo.connector.pusher.connection.bind('failed', (payload: any) => {
        /**
         * Channels is not supported by the browser.
         * This implies that WebSockets are not natively available and an HTTP-based transport could not be found.
         */

        console.log('failed', payload)
    })

    echo.connector.pusher.connection.bind('disconnected', (payload: any) => {
        /**
         * The Channels connection was previously connected and has now intentionally been closed
         */

        console.log('disconnected', payload)
    })

    echo.connector.pusher.connection.bind('message', (payload: any) => {
        /**
         * Ping received from server
         */

        console.log('message', payload)
    })
}
