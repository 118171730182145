

































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
    private email: string = ''
    private password: string = ''
    private error: boolean = false

    private async login() {
        await this.$auth.login({
            params: {
                email: this.email,
                password: this.password,
            },
            error: () => {
                this.$snotify.error('There was an issue with your login.')
            },
            rememberMe: true,
            redirect: '/',
            fetchUser: true,
        })

        this.$echo.options.auth = {
            headers: { Authorization: `Bearer ${this.$auth.token()}` },
        }

        this.$echo.connector.options.auth.headers.Authorization = `Bearer ${this.$auth.token()}`
    }
}
