






import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class Loading extends Vue {
    @Prop({ default: false }) fixed: boolean
}
