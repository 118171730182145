var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.addModalOpen
        ? _c("Modal", {
            on: {
              "modal-close": function($event) {
                return _vm.handleModalClose()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card toolbox" }, [
        _vm.user
          ? _c("div", { staticClass: "btn-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.firstLetterOfName))]),
              _vm._v(" "),
              _c("strong", [_vm._v(_vm._s(_vm.user.name))])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isAdmin
          ? _c(
              "button",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.addModalOpen = true
                  }
                }
              },
              [_vm._v("Add New Item")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn--grey",
            on: {
              click: function($event) {
                _vm.hidePurchased = !_vm.hidePurchased
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-eye" }),
            _vm._v(
              " " +
                _vm._s(_vm.hidePurchased ? "Show" : "Hide") +
                " Purchased Items"
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.filteredItems, function(item) {
        return _c("Item", { key: item.id, attrs: { item: item } }, [
          _c("div", { staticClass: "item__tools" }, [
            !item.purchased
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn--green",
                    on: {
                      click: function($event) {
                        return _vm.updateItem(item, true)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-shopping-cart" }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Mark Item as Purchased")])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isAdmin
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn--thin btn--red",
                    on: {
                      click: function($event) {
                        return _vm.deleteItem(item)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-trash" })]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          item.purchased
            ? _c("div", { staticClass: "item__purchased" }, [
                _c("div", [
                  _c("i", { staticClass: "fas fa-check-circle" }),
                  _c("br"),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(
                      _vm._s(item.title) +
                        " purchased by: " +
                        _vm._s(item.user.name)
                    )
                  ]),
                  _vm._v(" "),
                  item.purchased_by === _vm.user.id || _vm.isAdmin
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn--red",
                          on: {
                            click: function($event) {
                              return _vm.updateItem(item, false)
                            }
                          }
                        },
                        [_vm._v("Unmark Item as Purchased")]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      _vm.loading ? _c("Loading", { attrs: { fixed: true } }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }