































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'

@Component
export default class Register extends Vue {
    private name: string = ''
    private email: string = ''
    private password: string = ''
    private error: boolean = false
    private errors: any = {}
    private success: boolean = false

    private register() {
        this.$auth.register({
            data: {
                name: this.name,
                email: this.email,
                password: this.password,
            },
            success: () => {
                this.$snotify.success(
                    `Successfully registered account ${this.name}`
                )
            },
            error: (res: any) => {
                this.$snotify.error(
                    'There was an issue registering this account.'
                )
            },
        })
    }
}
