var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v("Dashboard")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("You successfully accessed this page restricted to admins")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }