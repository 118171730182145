
















































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

@Component({
    beforeRouteLeave(to, from, next) {
        this.$echo.leave('chat')

        next()
    },
})
export default class Chat extends Vue {
    private messages: any[] = []
    private newMessage: string = ''
    private disableInput: boolean = false
    private users: any[] = []
    private activeUser: any = null
    private typingTimer: any = null
    async created() {
        await this.getMessages()
        this.$echo
            .join('chat')
            .here((users: any) => {
                this.users = users
            })
            .joining((user: any) => {
                this.users.push(user)
                this.$snotify.info(`${user.name} joined the chat.`)
            })
            .leaving((user: any) => {
                this.users = this.users.filter((u: any) => u.id !== user.id)
                this.$snotify.info(`${user.name} left the chat.`)
            })
            .listen('MessageSent', (event: any) => {
                this.messages.push(event.message)
                this.activeUser = false
            })
            .listenForWhisper('typing', (user: any) => {
                this.activeUser = user
                if (this.typingTimer) {
                    clearTimeout(this.typingTimer)
                }
                this.typingTimer = setTimeout(() => {
                    this.activeUser = false
                }, 3000)
            })
    }

    async getMessages() {
        const res = await this.axios.get('messages')
        this.messages = res.data.content
    }

    async handleSubmit() {
        this.disableInput = true
        const res = await this.axios.post('messages', {
            message: this.newMessage,
        })

        if ((res.status = 200)) {
            this.newMessage = ''
        }

        this.disableInput = false
        const el: any = this.$refs.input
        el.focus()
    }

    async handleTyping() {
        this.$echo.join('chat').whisper('typing', this.$auth.user())
    }
}
