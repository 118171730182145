import WebFont from 'webfontloader'
import Vue from 'vue'
import Root from './components/Index.vue'
import store from './state'
import router from './routing'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueAuth from '@websanova/vue-auth'
import routerDriver from '@websanova/vue-auth/drivers/router/vue-router.2.x.js'
import axiosDriver from '@websanova/vue-auth/drivers/http/axios.1.x.js'
import authDriver from '@websanova/vue-auth/drivers/auth/bearer.js'
import PlatformSettingsService from './services/PlatformSettingsService'
import { Container } from 'typedi'
import VueEcho from 'vue-echo-laravel'
import VueChatScroll from 'vue-chat-scroll'
import { bootstrapEchoDebug } from './utils/echo-debug'
const Pusher = require('pusher-js')
// import { getModule } from 'vuex-module-decorators'

const platformSettings: PlatformSettingsService = Container.get(
    PlatformSettingsService
)

/**
 * Boostrap axios to use laravels CSRF
 */

const windowRef = window as any
windowRef.axios = axios

windowRef.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
}

const token: any = document.head.querySelector('meta[name="csrf-token"]')

windowRef.laravel = {
    csrf: token.content,
}

windowRef.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
axios.defaults.baseURL = `${platformSettings.baseUrl}api`

/**
 * Webfonts
 */

WebFont.load({
    typekit: {
        id: 'qef3nzw',
    },
})

/**
 * Vue
 */

Vue.use(Snotify, {
    toast: {
        icon: false,
        position: SnotifyPosition.rightTop,
    },
})

Vue.use(VueChatScroll)

Vue.use(VueAxios, axios)

Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: 'pusherkey',
    wsHost:
        process.env.APP_ENV === 'local'
            ? window.location.hostname
            : 'socket.learnerboss.com',
    wsPort: 6001,
    wssPort: 443,
    forceTLS: process.env.SSL_WEBSOCKETS === 'true',
    disableStats: true,
})

Vue.router = router

Vue.use(vueAuth, {
    auth: authDriver,
    http: axiosDriver,
    router: routerDriver,
    rolesKey: 'role_names',
})

const renderApp = () => {
    return new Vue({
        el: '#app',
        store,
        router,
        render: (h: any) => h(Root),
        created: async function () {
            this.$auth.load().then(() => {
                process.env.CONSOLE_DEBUGGING === 'true'
                    ? bootstrapEchoDebug(this.$echo)
                    : null
                if (this.$auth.token()) {
                    this.$echo.options.auth = {
                        headers: {
                            Authorization: `Bearer ${this.$auth.token()}`,
                        },
                    }
                    this.$echo.connector.options.auth.headers.Authorization = `Bearer ${this.$auth.token()}`
                }
            })
        },
    })
}

renderApp()
