var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("h1", [_vm._v("Map Area Proof of Concept")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Shows idea of how instructors will create the areas that they service."
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "flex", border: "1px solid rgba(0,0,0,0.1)" } },
      [
        _vm.showMap
          ? _c(
              "l-map",
              {
                staticStyle: { height: "500px", flex: "4" },
                attrs: {
                  zoom: _vm.zoom,
                  center: _vm.center,
                  options: _vm.mapOptions
                },
                on: {
                  "update:center": _vm.centerUpdate,
                  "update:zoom": _vm.zoomUpdate
                }
              },
              [
                _c("l-tile-layer", {
                  attrs: { url: _vm.url, attribution: _vm.attribution }
                }),
                _vm._v(" "),
                _c("l-free-draw", {
                  attrs: { mode: _vm.mode },
                  model: {
                    value: _vm.polygons,
                    callback: function($$v) {
                      _vm.polygons = $$v
                    },
                    expression: "polygons"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { flex: "1", height: "100%", padding: "15px" } },
          [
            _c("h2", [_vm._v("Service Areas")]),
            _vm._v(" "),
            _vm.polygons.length === 0
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      "You haven't added any service areas, users will not see you when searching."
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.polygons, function(polygon, index) {
              return _c(
                "div",
                { key: index, staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "0 15px",
                        border: "1px solid rgba(0,0,0,0.1)",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("h4", [_vm._v("Area " + _vm._s(index + 1))]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.removeArea(polygon)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-trash",
                            staticStyle: { color: "red" }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [
                !_vm.isActive
                  ? _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            _vm.isActive = true
                          }
                        }
                      },
                      [_vm._v("Enable Map Editing")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            _vm.isActive = false
                          }
                        }
                      },
                      [_vm._v("Save & close")]
                    )
              ]
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }