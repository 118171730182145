var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal" }, [
    _c("div", {
      staticClass: "modal__background",
      on: { click: _vm.modalClose }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "modal__content" }, [
      _c("h1", [_vm._v("Add New Item")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { autocomplete: "off", method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "title" } }, [_vm._v("Title")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.title,
                  expression: "form.title"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "title",
                placeholder: "",
                required: ""
              },
              domProps: { value: _vm.form.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "title", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "description" } }, [
              _vm._v("Description")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.description,
                  expression: "form.description"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "description",
                placeholder: "",
                required: ""
              },
              domProps: { value: _vm.form.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "description", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Price")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.price,
                  expression: "form.price"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "price",
                placeholder: "",
                required: ""
              },
              domProps: { value: _vm.form.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "price", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Product URL")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.product_url,
                  expression: "form.product_url"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "title",
                placeholder: "",
                required: ""
              },
              domProps: { value: _vm.form.product_url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "product_url", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Image URL")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.image_url,
                  expression: "form.image_url"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "title",
                placeholder: "",
                required: ""
              },
              domProps: { value: _vm.form.image_url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "image_url", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-default", attrs: { type: "submit" } },
            [_vm._v("Add New Item")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "modal__close", on: { click: _vm.modalClose } },
        [_c("i", { staticClass: "fas fa-times" })]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }