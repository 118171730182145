























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Modal from '../modal/Modal.vue'
import Loading from '../loading/loading.vue'
import Item from '../item/item.vue'

@Component({ components: {
    Modal,
    Loading,
    Item
}})
export default class Home extends Vue {
    private user: any = null
    private addModalOpen: boolean = false
    private wishlistItems = []
    private loading: boolean = false
    private hidePurchased: boolean = false
    private created() {
        this.user = this.$auth.user()
        console.log(this.user)

        this.getItems();
    }

    private async getItems() {
        this.loading = true
        const response = await this.axios.get('item')
        this.wishlistItems = response.data.content
        this.loading = false
    }

    private async deleteItem(item: any) {

        if(window.confirm(`Delete item: ${item.title}?`)) {
            this.loading = true
            const response = await this.axios.delete(`item/${item.id}`)

            this.getItems();
            this.loading = false;
        }
    }

    private async updateItem(item: any, purchased: boolean) {
        try {
            this.loading = true

            const response = await this.axios.patch(`item/${item.id}`, {
                item: { ...item, ...{ purchased}}
            })

            if(response.status === 200) {
                this.$snotify.success(`Successfully ${purchased ? 'marked' : 'unmarked'} ${item.title}`)
            }

        } catch (error) {
            if(error.response.status === 409) {
                this.$snotify.error(error.response.data.msg, '', { timeout: false, closeOnClick: true})
            } else {
                this.$snotify.error('An unexpected error occured.');
            }
        } finally {
            this.getItems();
            this.loading = false
        }

    }

    private handleModalClose() {
        this.addModalOpen = false
        this.getItems()
    }

    get isAdmin(): boolean {
        return this.user.roles.find((role: any) => role.name === 'admin')
    }

    get firstLetterOfName() {
        return this.user.name.charAt(0)
    }

    get filteredItems() {
        if(this.hidePurchased) {
            return this.wishlistItems.filter((item: any) => item.purchased === 0)
        }

        return this.wishlistItems
    }
}
