




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class Item extends Vue {
    @Prop() item: any
}
